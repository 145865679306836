html {
    scroll-behavior: smooth;
}

/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
    display:inline;
}

.fa-facebook-f:hover {
    color: #3b5998;
}

.fa-twitter:hover {
    color: #1da1f2;
}

.fa-linkedin-in:hover {
    color: #0077b5;
}

.front-title-wrapper {
    padding: 5%;
}

h1.front-title {
    font-family: "Merriweather Sans", sans-serif;
    letter-spacing: -1px;
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

h1.front-title strong {
    font-weight: bold;
    color: #268bd2;
}

p.front-subtitle {
    font-size: 20px;
    margin-bottom: 0.8rem;
    color: rgba(0, 0, 0, 0.5);
}

h2.front-section-title {
    font-family: "Merriweather Sans", sans-serif;
    font-size: 36px;
    font-weight: bold;
}


.mainheading h1.posttitle {
    font-family: "Roboto", sans-serif;
    font-size: 44px;
    margin-bottom: 0.8rem;
}

.mainheading {
    padding: 0rem;
    padding-top: 1rem;
    margin-bottom: 2.5rem;
}

.mainheading .post-date {
    color: #aaaaaa;
    padding-left: 1rem;
    font-weight: 300;
}

.mainheading .post-date-updated {
    font-style: italic;
    color: #aaaaaa;
    padding-left: 0.5rem;
    font-weight: 300;
}

span.category-label {
    color: #aaaaaa;
    font-weight: 300;
    font-size: 0.9rem;
}

.article-post {
    font-family: 'Lato', sans-serif;
    margin-bottom: 2rem;
}

.share.sticky-top-offset {
    top: 157px;
    margin-bottom: 2rem;
}

.extend-r {
    margin-right: -30px;
}

.extend-l {
    margin-left: -30px;
}

h2.card-title {
    font-family: "Roboto", sans-serif;
}

.card span.post-date {
    font-size: 0.75em;
}

.card {
    background-color: unset;
}

.card-body {
    padding: 1.5rem
}

.main-content .container {
    padding-left: 0px;
    padding-right: 0px;
}

.card-footer {
    background-color: unset;
}


.card-title {
    margin-bottom: 0.25rem;
}

.related-post-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-content {
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
}

.utterances {
    max-width: 850px;
}

.navbar .container {
    max-width: 1200px;
}

.footer .container {
    max-width: 1200px;
}

.site-content {
    margin-top: unset;
    padding-top: 2.5rem;
}

.navbar .fab {
    font-size: 1.1rem;
}

.mediumnavigation .nav-item, .dropdown-menu {
    font-size: unset;
}

.site-title {
    font-size: 1.6rem;
    font-weight: 700;
    color: #444444;
    letter-spacing: 0;
    margin-bottom: 0;
}


.site-title .subtitle {
    font-size: 1.3rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: 0;
}

.nav-link {
    text-align: right;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,0.6);
}

.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0,0,0,0.8);
}

.navbar {
    padding: 0.5rem 1.5rem;
}

.footer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.site-content .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}


.bottompagination span.navigation {
    border-top: unset;
}

.list-group-horizontal .list-group-item {
    border: unset;
    background-color: unset;
    text-align: center;
}

img.mini-app-list-icon {
    max-width: 24px;
}

.app-list .list-group-item {
    width: 20%;
}

.app-list .list-group-item {
    padding: 8px;
}

.mini-app-list .list-group-item {
    padding: 5px;
}

// img.app-list-icon {
//     max-width:100px;
// }

.company-list .list-group-item {
    width: 33%;
}


@media (min-width:992px) {
    img.company-list-icon {
        max-width: 200px;
    }

}

@media (max-width:991px) {
    img.company-list-icon {
        max-width: 100%;
    }
}



.panel {
    min-height: 50vh;
    padding-bottom: 5rem;
    padding-top: 5rem;
}

.bg-base {
    background-color: #fff;
}

.bg-grey {
    background-color: rgb(90, 90, 90);
}

.bg-darkgrey {
    background-color: rgb(40, 40, 40);
}


img.front-circle-img {
    border-radius: 16px;
    box-shadow: 0px 0px 5px 0px #AAAAAA;
}

.front-image-wrapper {
    margin-left: auto;
    margin-right: auto;
    position: absolute;

    width: 300px;
    height: 600px;



    // height: 600px;
    transform: rotate(60deg);
    overflow:hidden;
    margin-top: -150px;
    // box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 1);
    // background-color: #0077b5;

    // text-align: center;
}

.front-image-wrapper img {
    width: 100%;
    height: 50%;
    margin-top: 150px;
    transform: rotate(-60deg);
}



.front-image-under {
    position: absolute;
    width: 290px;
    height: 290px;
    top: 5px;
    left: 124px;
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    z-index: -1;
    box-shadow: 0px 0px 1px 0px #EEEEEE;
}

.front-image-under::after {
    content: "";
    position: absolute;
    top: 118px;

    left:230px;
    background-color: #282828;
    height: 300px;
    width: 3px;
    z-index: -1;
    box-shadow: 0px 0px 8px 1px #BBBBBB;
    transform: rotate(60deg);
}


.center-vertically {
    display: flex;
    align-items: center;
}


button.btn.btn-primary {
    background-color: #268bd2;
    border-radius: 5px;
    border-color: #268bd2;
    color: #eeeeee;
}

button.btn.btn-primary:hover {
    background-color: #1e73b0;
    border-color: #1e73b0;
    color: #ffffff;
}

.footer-social-links {
    justify-content: center;
}

.footer-social-links .list-group-item span {
    font-size: 1rem;
}

.footer-social-links .list-group-item {
    font-size: 22px;
    padding: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.footer-social-links .list-group-item a {
    color:rgba(0, 0, 0, 0.6);
}

.footer-social-links .list-group-item a:hover {
    color:rgba(0, 0, 0, 0.8);
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

.projectbox {
    box-shadow: 0px 0px 10px 1px #EEEEEE;
}

button.btn.btn-secondary {
    background-color: unset;
    border-radius: 5px;
    border-color: #268bd2;
    color: #eeeeee;
}

.integrate-with {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.75);
    font-size: 0.8rem;
    font-weight: bold;
    margin-right: 0.5rem;
    white-space: nowrap;
    margin-top: 3px;
}


.no-min-height {
    min-height: unset;
}

.bordered-image {
    filter: drop-shadow(3px 0 0 white)
        drop-shadow(0 3px 0 white)
        drop-shadow(-3px 0 0 white)
        drop-shadow(0 -3px 0 white);
}

@media screen and (min-width: 700px)  {
    .mobile-break { display: none; }

    .site-title .subtitle {
        padding-left: 0.5rem;
    }
}


.nav-down .navbar-nav {
    background-color: white;
    text-align: right;
    align-items: end;
    padding-right: 1rem;
    background-color: white;
}

.nav-down {
    z-index: 100;
}
